
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");
* {
padding: 0;
margin: 0;
box-sizing: border-box;
font-family: "Poppins", sans-serif;
user-select: none;
-webkit-user-select: none;
scroll-behavior: smooth;
}
::-webkit-scrollbar {
display:none
}
body {
background-color: #000;
overflow-y: auto;
overflow-x: hidden;
margin: 0;
height: 100vh;
position: relative;
}
.App {
position: relative;
z-index: 1;
}
.star {
z-index: 0;
position: absolute;
width: 2px;
height: 2px;
background: #fff;
box-shadow: 0 0 10px #fff;
border-radius: 50%;
opacity: 0;
animation: moveStar linear;
}
@keyframes moveStar {
0% {
transform: translate(-50px, 50px);
opacity: 0;
}
10% {
opacity: 1;
}
90% {
opacity: 1;
}
100% {
transform: translate(500px, -500px);
opacity: 0;
}
}
.stars {
position: absolute;
width: 100%;
height: 100%;
overflow: hidden;
top: 0;
left: 0;
z-index: 0;
}