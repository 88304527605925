:root {
	--secondaryColor: #db2b39;
	--textColor: #eee;
	--discordButtonColor: #0056b3;
	--discordButtonTextColor: #ffffff;
	}
	header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100px;
	padding: 10px 2rem;
	background-color: #0000007e;
	color: var(--textColor);
	border-radius: 20px;
	margin: 10px;
	border: none;
	}
	.logo {
	transform: scale(0.79);
	height: auto;
	margin-right: 20px;
	margin-left: -10px;
	}
	.logo-text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 2px;
	text-align: center;
	}
	.logo-title {
	display: flex;
	font-size: 1.6rem;
	font-weight: 700;
	color: var(--textColor);
	}
	.logo-subtitle {
	font-size: 1.1rem;
	font-weight: 300;
	color: var(--textColor);
	}
	.nav-links {
	display: flex;
	align-items: center;
	gap: 28px;
	margin-left: auto;
	}
	nav a {
	color: var(--textColor);
	text-decoration: none;
	font-size: 1.4rem;
	padding: 10px 20px;
	border-radius: 5px;
	transition: 0.3s;
	}
	nav a:hover {
	color: var(--textColor);
	background-color: rgba(255, 255, 255, 0.2);
	}
	.join-discord {
	background-color: var(--discordButtonColor);
	color: var(--discordButtonTextColor);
	border: 2px solid var(--discordButtonColor);
	border-radius: 10px;
	padding: 12px 24px;
	cursor: pointer;
	font-size: 1.1rem;
	transition: background-color 0.3s, color 0.3s;
	}
	.join-discord:hover {
	background-color: var(--discordButtonTextColor);
	color: var(--discordButtonColor);
	border: 2px solid var(--discordButtonColor);
	}
	.join-discord:focus {
	outline: none;
	}