.projects-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 100px);
    color: #fff;
    border-radius: 25px;
    text-align: center;
    padding: 0 80px;
    }
    .projects-title {
    font-size: 2.5rem;
    padding-bottom: 10px;
    border-bottom: #f39c12 2px solid ;
    color: #fff;
    margin-top: 80px;
    font-weight: 700;
    letter-spacing: 2px;
    text-align: center;
    }
    .typewriter-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    }
    .typewriter-text {
    margin-top: 10px;
    text-align: center;
    }
    .typewriter {
    font-size: 1.5rem;
    font-family: 'Poppins', sans-serif;
    color: #ffffff50;
    letter-spacing: 1.5px;
    border-right: 2px solid #fff;
    white-space: nowrap;
    overflow: hidden;
    width: 0;
    animation: typing 3.5s steps(40, end) forwards, blink 0.75s step-end infinite;
    }
    @keyframes typing {
    from { width: 0; }
    to { width: 100%; }
    }
    @keyframes blink {
    from, to { border-color: transparent; }
    50% { border-color: white; }
    }