.main {
  height: 100vh;
  }
  .about-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 30vh;
  color: #fff;
  border-radius: 25px;
  text-align: center;
  padding: 0 80px;
  }
  .about-title {
  font-size: 2.5rem;
  padding-bottom: 10px;
  border-bottom: #f39c12 2px solid;
  color: #fff;
  margin-top: 80px;
  font-weight: 700;
  letter-spacing: 2px;
  text-align: center;
  }
  .hero-section-2 {
  position: absolute;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -25%);
  height: max-content;
  }
  .button-container {
  display: flex;
  justify-content: center;
  gap: 25px;
  margin-top: 20px;
  }
  .hero-button-about {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #f39c12;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  }
  .hero-button-about:hover {
  background-color: #e67e22;
  }
  .button-icon {
  margin-right: 8px;
  font-size: 1.5rem;
  image-rendering: optimizeQuality;
  }
  .about-content-section {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 40px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 15px;
  max-width: 1500px;
  color: #fff;
  height: fit-content;
  text-align: center;
  }
  .about-info-container {
  width: 40%;
  text-align: left;
  position: relative;
  margin-right: 20px;
  }
  .middle-separator {
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translateY(-50%);
  width: 2px;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 0 20px;
  height: 50%;
  }
  .subheading-wrapper {
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  margin-left: 20px;
  }
  .about-subheading {
  font-size: 1.5rem;
  color: #ffffff75;
  margin: 20px 0;
  }
  .about-heading {
  font-size: 2rem;
  color: #ffffff75;
  margin: 10px 0;
  }
  .about-text {
  font-size: 1.2rem;
  margin: 20px 0;
  }
  .about-text a {
  color: #f39c12;
  text-decoration: none;
  }
  .about-text a:hover {
  text-decoration: underline;
  }
  .align-left {
  text-align: left;
  }
  .social-icons {
  display: flex;
  gap: 15px;
  margin-top: 10px;
  }
  .social-icon {
  font-size: 2rem;
  color: #fff;
  transition: color 0.3s ease;
  text-decoration: none;
  }
  .social-icon.discord:hover {
  color: #7289da;
  }
  .social-icon.youtube:hover {
  color: #ff0000;
  }
  .social-icon.twitter:hover {
  color: #1da1f2;
  }
  .social-icon.github:hover {
  color: #6e5494;
  }